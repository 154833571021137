import  React                           from 'react';
import extHtmlToReact                   from 'html-to-react';

export class HtmlToReact extends React.Component {
    render(){
        const components             = this.props.components;
        const attribMap              = this.props.attributes;
        const processingInstructions = [
        {
            shouldProcessNode: function (node) {
            return components[node.name] !== undefined;
            },
            processNode: function (node, children) {
            const nodeName = components[node.name];

            let attribs    = [node.attribs][0];

            attribs.key    = attribs.key || node.name;

            const attribs_keys = Object.entries(attribs)
            if (attribs_keys) {
                for (let [key, value] of attribs_keys){
                if (key in attribMap) {
                    attribs[attribMap[key]] = attribs[key]
                    delete attribs[key]
                }
                }
            }


            return React.createElement(
                nodeName,
                attribs,
                [...children]
            );
            }
        },
        {
            shouldProcessNode: function (node) {
            return true;
            },
            processNode: new extHtmlToReact.ProcessNodeDefinitions(React).processDefaultNode
        }
        ];
        return new extHtmlToReact.Parser().parseWithInstructions(this.props.htmlToParse,this.props.isValidNode, processingInstructions);
    }
}
export default HtmlToReact;
